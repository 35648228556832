import axios from "axios";

import { setAccessToken } from "@/lib/authentication";
import { auth } from "@/lib/links";

let idleSessionTimer: ReturnType<typeof setTimeout>;

/**
 * We prefer to fetch the idle session timeout from the organization
 * but we don't always have an org available such as on the support
 * admin pages so we default to 15 minutes and then allow it to be
 * overridden and reset if an org is loaded.
 */
let idleSessionTimeout: number = 15;

/**
 * The organization endpoint returns the idle session timeout.
 * So instead of creating a new endpoint to fetch it, we can
 * just pull it off of that response and then call this function
 * to set it and kickoff the timer. Almost everything needs
 * a organization. When an org isn't needed (e.g. in the
 * super admin support pages) then we'll have to set this
 * manually to a default value.
 *
 * Until we're 100% in React, we can't use hooks here.
 * We must keep this in plain JS for now. When we're all in
 * React then we can update this to use hooks such as calling
 * useOrganization() and pulling it off of the response here
 * where we need it instead of having the hook call this method.
 *
 * Set the timeout to null to disable it.
 */
export function setIdleSessionTimeout(timeout: number | null) {
  idleSessionTimeout = timeout;
  resetIdleSessionTimeout();
}

/**
 * Set an idle timer to log the user out after a certain amount of time
 * passes without any activity. The timer is reset whenever an API
 * request is made.
 */
export function resetIdleSessionTimeout() {
  clearTimeout(idleSessionTimer);

  if (!idleSessionTimeout) {
    return;
  }

  idleSessionTimer = setTimeout(() => {
    setAccessToken(null);

    axios({
      url: `/authentication/logout?redirect_to=${window.location.pathname}`,
      method: "POST",
    })
      .then(() => {
        window.location.href = auth.login();
      })
      .catch((error) => {
        console.log(error);
      });
  }, idleSessionTimeout * 60 * 1000);
}

resetIdleSessionTimeout();
